<script setup lang="ts">
import { ref, reactive } from 'vue'

// Define props
const props = defineProps<{
  heading: string
  subheading: string
  btnLabel: string
  singleRow: boolean
}>()

interface SubscribersResponse {
  id: number
  message: string
  error: any
}

// Define env
const config = useRuntimeConfig()

// Reactive state
const subscriber = reactive({
  email: null as string | null
})
const subscriberId = ref<string | null>(null)
const isLoading = ref(false)
const success = ref<{ status: boolean; message: string; complete?: boolean } | null>(null)
const error = ref<{ status: boolean; message: string } | null>(null)

// Page head metadata
useHead({
  title: 'Kinfolk Chronicles',
  meta: [
    { hid: 'description', name: 'description', content: 'Subscribe to our mailing list' },
    { hid: 'og:title', property: 'og:title', content: 'Subscribe' },
    { hid: 'og:description', property: 'og:description', content: 'Subscribe to our mailing list' },
    { hid: 'og:image', property: 'og:image', content: '' }
  ]
})

function isValidEmail(data: string | null): boolean {
  const emailPattern = /^(https?:\/\/)?[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?!co$)(?:co\.[a-zA-Z]{2,}|[a-zA-Z]{2,})$/
  return data ? emailPattern.test(data) : false
}

// Function to handle subscription
async function subscribe() {
  isLoading.value = true;
  const newSubscriber = { email: subscriber.email };

  try {
    // Using $fetch with a try-catch block for detailed error handling
    const response = await $fetch<SubscribersResponse>(`${config.public.CMS_PRODUCTION_BASE_URL}/subscribers`, {
      method: 'POST',
      body: newSubscriber,
    });

    // Checking if response contains an id (indicating success)
    if (response && response.id) {
      subscriber.email = null;
      error.value = null;
      success.value = {
        status: true,
        message: 'You have successfully subscribed to our newsletter!',
      };
    } else {
      // Handle unexpected response structure
      throw new Error(response.error?.message || 'An unknown error occurred');
    }
  } catch (err: any) {
    // Handling error and displaying the error message if available
    error.value = {
      status: true,
      message: err?.data?.error?.message || err.message || 'An error occurred during subscription',
    };
  } finally {
    isLoading.value = false;
    setTimeout(() => {
      success.value = null;
      if (success.value === null) {
        success.value = {
          status: false,
          message: '',
          complete: true,
        };
      }
      error.value = null;
    }, 5000);
  }
}
</script>

<template>
  <div class="min-h-[max-content] px-4">
    <div v-if="success === null || success.status === false" class="flex justify-center w-full h-full"
      :class="props.singleRow ? 'md:flex-row flex-col md:items-start items-center' : 'flex-col'">
      <h1 v-if="!props.singleRow" class="text-center mb-6">{{ props.heading }}</h1>
      <p v-if="!props.singleRow" class="max-w-[800px] mx-auto text-center">
        {{ props.subheading }}
      </p>
      <div v-else class="h-full pt-1 md:mb-0 mb-3">
        <h3 class="text-md mr-4 !font-[500] !font-Playfair">{{ props.heading }}</h3>
      </div>
      <div class="min-w-[320px] md:w-[max-content] w-full h-full" :class="props.singleRow ? '' : 'mx-auto'">
        <form class="newsletterForm flex sm:flex-row flex-col items-center justify-center"
          :class="props.singleRow ? 'md:w-auto w-full' : 'mt-6'" @submit.prevent="subscribe">
          <input id="email" v-model="subscriber.email" :onchange="isValidEmail(subscriber.email)" type="email"
            name="email"
            class="textInput border text-black sm:mr-3 mr-0 sm:mb-0 mb-3 rounded-md text-[15px] md:w-[320px] w-full focus:outline-none focus:border-gray-300 focus:ring-1 focus:ring-gray-300 disabled:bg-slate-50 disabled:border-slate-200 disabled:shadow-none invalid:border-red-600 invalid:text-red-600"
            :class="`
              ${subscriber.email && !isValidEmail(subscriber.email) ? 'text-red-600 border-red-600 focus:ring-red-600' : '!text-black'}
              ${props.singleRow ? 'py-1 px-3 placeholder:text-xs' : 'py-2 px-5'}
            `" placeholder="* Your email address">
          <button
            class="rounded-md border disabled:border-neutral-500 py-2 px-3 min-w-[106px] flex items-center justify-center disabled:bg-neutral-500 disabled:border sm:w-auto w-full"
            :class="props.singleRow
              ? 'text-xs font-bold sm:text-[12px] min-h-[max-content] bg-white border-white text-black disabled:text-black'
              : 'py-2 text-sm sm:text-[15px] min-h-[40px] bg-black border-black text-white disabled:text-white'
              " type="submit" :disabled="!isValidEmail(subscriber.email)">
            <span v-if="!isLoading">{{ props.btnLabel }}</span>
            <span v-else class="w-3 h-3">
              <svg class="animate-spin duration-1000" width="15px" height="15px" viewBox="0 0 20 20" version="1.1"
                xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="🔍-System-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g id="ic_fluent_spinner_ios_20_regular" fill="white" fill-rule="nonzero">
                    <path id="🎨-Color"
                      d="M10,3 C6.13401,3 3,6.13401 3,10 C3,10.2761 2.77614,10.5 2.5,10.5 C2.22386,10.5 2,10.2761 2,10 C2,5.58172 5.58172,2 10,2 C14.4183,2 18,5.58172 18,10 C18,14.4183 14.4183,18 10,18 C9.72386,18 9.5,17.7761 9.5,17.5 C9.5,17.2239 9.72386,17 10,17 C13.866,17 17,13.866 17,10 C17,6.13401 13.866,3 10,3 Z" />
                  </g>
                </g>
              </svg>
            </span>
          </button>
        </form>
        <span v-if="error?.status === true" class="w-full">
          <p :class="error?.status ? 'text-[red] text-xs' : 'text-black text-lg'" class="px-2 mt-2">
            {{ error?.message }}
          </p>
        </span>
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center my-4">
      <span class="flex flex-col items-center justify-center">
        <b class="text-lg">Thanks for signing up.</b>
        <p>Congratulations 🎉 on joining the best newsletter in the world!</p>
      </span>
    </div>
  </div>
</template>

<style></style>
